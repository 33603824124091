import React from 'react';
import {
  BottomContainer,
  BottomContent,
  Text,
} from './styles';

const Bottom = () => (
  <BottomContainer>
    <BottomContent>
      <Text>
        <h3>LCK Importação e Comércio</h3>
        <span>Rua Ribeirão Branco, 116 - anexo 102</span>
        <span>Vila Bertioga - São Paulo - SP</span>
        <span>CEP: 03188-050</span>
      </Text>

      <Text className="telefones">
        <span>Telefones:</span>
        <span>(11) 2412-0008</span>
        <span>(11) 9 4181-7158</span>
      </Text>
    </BottomContent>
  </BottomContainer>
)

export default Bottom
