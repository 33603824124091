import React from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  margin: 0;
  padding: 0;
`

export default function Layout({ children }) {
  return (
    <Wrapper>
      <Global
        styles={css`
          html, body {
            margin: 0;
            padding: 0;
            font-family: Ubuntu, Arial;
          }
        `}
      />
      {children}
    </Wrapper>
  )
}
