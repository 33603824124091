import React from 'react';
import { Layout } from 'components/Layout';
import logo from 'images/logo.png';
import {
  HeaderContainer,
  HeaderContent,
  LogoContainer,
  CompanyInfo,
  AddressContainer,
  ContactContainer,
} from './styles';

export default function Header() {
  return (
    <Layout>
      <HeaderContainer>
        <HeaderContent>

          <LogoContainer>
            <img src={logo} alt="Lucky Grobo" />
          </LogoContainer>

          <CompanyInfo>
            <AddressContainer>
              <span>Rua Ribeirão Branco, 116 - anexo 102</span>
              <span className="hide-small-display">
                Vila Bertioga - São Paulo - SP
              </span>
              <span>CEP: 03188-050</span>
            </AddressContainer>

            <ContactContainer>
              Telefones: <br/>
              (11) 2412-0008<br/>
              (11) 9 4181-7158
            </ContactContainer>
          </CompanyInfo>

        </HeaderContent>
      </HeaderContainer>
    </Layout>
  )
}
