import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
  position: relative;
  background-color: #1c1c1c;
  height: 80px;
  width: 100%;

  @media (max-width: 760px) {
    height: 120px;
  }
`

export const HeaderContent = styled.div`
  max-height: 200px;
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 200px auto;
  color: #fff;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: 600px) {
    grid-template-columns: 120px auto;
  }
`

export const LogoContainer = styled.div`
  background-color: #fff;
  width: 165px;
  text-align: center;
  padding: 20px 0;
  z-index: 100;

  img {
    width: 120px;
  }

  @media (max-width: 600px) {

    width: 100px;
    img {
      width: 80%;
    }
  }
`

export const CompanyInfo = styled.div`
  max-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 760px) {
    display: block;
    padding-top: 20px;
  }
`

export const AddressContainer = styled.div`
  padding-top: 20px;
  text-align: left;

  span {
    display: block;
  }

  @media (max-width: 760px) {
    padding-top: 0;
    display: block;
  }

  @media (max-width: 375px) {
    .hide-small-display {
      display: none;
    }
  }
`

export const ContactContainer = styled.div`
  display: grid;
  place-items: center;

  a {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 760px) {
    display: block;
  }
`
