import styled from "@emotion/styled";

export const MenuIconContainer = styled.div`
  color: #fff;
  margin: 15px;
  position: absolute;
  right: 5px;
  z-index: 100;

  &:hover {
    color: #004aad;
  }

  &.show {
    color: #004aad;
  }

  @media (min-width: 760px) {
    display: none;
  }
`

export const DropDownContainer = styled.div`
  color: #004aad;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  background-color: #fff;
  margin-top: -300%;
  transition: margin-top .4s ease-in-out;

  &.show {
    opacity: 95%;
    margin-top: 0;
  }

  @media (min-width: 760px) {
    display: none;
  }
`

export const DropDown = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 20px;

    a {
      color: #004aad;
      text-decoration: none;

      &:hover {
        color: #000;
      }
    }
  }
`
