import React, { useState } from 'react'
import { Link } from 'gatsby';
import { GiHamburgerMenu } from 'react-icons/gi'
import { MENU } from 'config/menu'
import {
  MenuIconContainer,
  DropDownContainer,
  DropDown,
  List,
} from './styles'

const DropDownMenu = () => {
  const [open, setOpen] = useState(false)

  const toggleMenu = () => setOpen(!open)

  const renderMenuItem = (item) => (
    <li key={item.label}>
      <Link to={item.path}>
        {item.label}
      </Link>
    </li>
  )

  return (
    <>
      <MenuIconContainer className={open ? 'show' : 'hide'}>
        <GiHamburgerMenu onClick={toggleMenu} />
      </MenuIconContainer>
      <DropDownContainer className={open ? 'show' : 'hide'}>
        <DropDown onClick={toggleMenu}>
          <List>
            { MENU.map(renderMenuItem) }
          </List>
        </DropDown>
      </DropDownContainer>
    </>
  )
}

export default DropDownMenu
