import React from 'react';
import { Link } from 'gatsby';
import { MENU } from 'config/menu'
import {
  Container,
  List,
  Content,
  ListContainer,
} from './styles'

export default function Menu() {
  const renderMenuItem = (item) => (
    <li key={item.label}>
      <Link to={item.path}>
        {item.label}
      </Link>
    </li>
  )

  return (
    <Container>
      <Content>
        <ListContainer>
          <List>
            { MENU.map(renderMenuItem) }
          </List>
        </ListContainer>
      </Content>
    </Container>
  )
}
