import React from 'react'

import { Header } from 'components/Header';
import { Menu } from 'components/Menu';
import { DropDownMenu } from 'components/DropDownMenu';

const Top = () => (
  <div>
    <DropDownMenu />
    <Header />
    <Menu />
  </div>
)

export default Top
