import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
  z-index: 90;
  background-color: #004aad;
  width: 100%;

  @media (max-width: 760px) {
    display: none;
  }
`

export const Content = styled.div`
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
`

export const ListContainer = styled.div`
`

export const List = styled.ul`
  margin: 0;
  margin-left: 165px;
  padding: 0;
  list-style: none;
  padding: 20px;
  font-size: 18px;
  font-weight: 400;

  li {
    display: inline;
    padding: 0 10px;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`
