import styled from "@emotion/styled";

export const BottomContainer = styled.div`
  background-color: #0e0e0e;
  color: #fff;
  padding: 30px 0 40px 0;
`

export const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 580px) {
    display: block;
  }
`

export const Text = styled.div`
  font-size: 14px;
  padding: 0 30px;
  line-height: 20px;

  span {
    display: block;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  &.telefones {
    padding-top: 40px;
  }
`
